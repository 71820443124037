;(function ($) {
	"use strict";

	/**
	 * jQuery loading indicator component.
	 *
	 * @type {Object}
	 */
	$.loadingIndicator = {
		/**
		 * The loader jQuery element.
		 * Will be created when opening the indicator.
		 * Contains the loading icon.
		 *
		 * @type {Null|jQuery}
		 * @private
		 */
		$loader: null,

		/**
		 * The default options for the indicator.
		 * When certain options were not passed, these will be used instead.
		 *
		 * @type {Object}
		 */
		defaults: {
			loaderCls: 'js--loading-indicator',
			iconCls: 'icon--default',
			delay: 0,
			animationSpeed: 500,
			closeOnClick: true,
			openOverlay: true
		},

		/**
		 * The extended options for the current opened overlay.
		 *
		 * @type {Object}
		 */
		options: {},

		init : function() {
			var me = this;
			$('#bkr-loading').on({
				'hide.uk.modal' : function() {
					$.publish('plugin/swLoadingIndicator/onCloseFinished', [ me ]);
				},
				'show.uk.modal' : function() {
					$.publish('plugin/swLoadingIndicator/onOpenFinished', [ me ]);
				},
			});
		},

		/**
		 * Opens/Shows the loading indicator along with the overlay.
		 * If the loader is not available, it will be created.
		 *
		 * @param {Object} indicatorOptions
		 */
		open: function (indicatorOptions, callback) {
			var me = this;

			me.options = $.extend({}, me.defaults, indicatorOptions);

			if (!me.$loader) {
				me.$loader = UIkit.modal('#bkr-loading', {
					center	: true,
					bgclose	: me.options.closeOnClick,
				});
				me.init();
			} else {
				me.$loader = UIkit.modal('#bkr-loading', {
					center	: true,
					bgclose	: me.options.closeOnClick,
				});
			}

			me.$loader.show();
			setTimeout(function() {
				if (typeof callback === 'function') callback();
			}, 400);

			$.publish('plugin/swLoadingIndicator/onOpen', [ me ]);
		},

		/**
		 * Closes the loader element along with the overlay.
		 */
		close: function (callback) {
			var me = this,
				opts = me.options;

			callback = callback || function() {};

			if (me.$loader !== null) {
				me.$loader.hide();

				setTimeout(function() {
					if (typeof callback === 'function') callback();
				}, 400);
			}

			$.publish('plugin/swLoadingIndicator/onClose', [ me ]);
		},
	};
})(jQuery);
