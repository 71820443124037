$.plugin('bkrModalStaffelpreise', {
	defaults: {
		selectorModal				: '#modal-staffelpreise',
		attributeName				: 'data-staffelpreise',
		selectorBtnStaffelpreise	: '*[data-staffelpreise]',
	},

	init : function() {
		var me		= this;

		this.$modal	= $(this.opts.selectorModal);
		this.modal	= UIkit.modal(this.$modal, {
			center	: true,
		});

		$(this.opts.selectorBtnStaffelpreise).on('click', $.proxy(this.show, this));

		$.subscribe('plugin/swInfiniteScrolling/onFetchNewPageLoaded', function(e, plugin, template) {
			$(me.opts.selectorBtnStaffelpreise).off('click', $.proxy(me.show, me));
		});

		$.subscribe('plugin/swInfiniteScrolling/onFetchNewPageFinished', function(e, plugin, template) {
			$(me.opts.selectorBtnStaffelpreise).on('click', $.proxy(me.show, me));
		});
	},

	show : function(e) {
		var me	= this;
		var url	= $(e.currentTarget).attr(this.opts.attributeName);
		if (!url) return;

		$.loadingIndicator.open();
		$.loadingIndicator.$loader.on('hide.uk.modal', abortRequest);

		var $request = $.ajax({
			url		: url,
			type	: 'GET',
			success	: function(response) {
				$.loadingIndicator.$loader.off('hide.uk.modal', abortRequest);
				me.$modal.find('.uk-modal-content').html(response);
				$.loadingIndicator.close(function() {
					me.modal.show();
				});
			}
		});

		function abortRequest() {
			$.loadingIndicator.$loader.off('hide.uk.modal', abortRequest);
			$request.abort();
		}
	},

	hide : function() {
		var me = this;
		this.modal.hide();
	},
});
