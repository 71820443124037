var debug;

$(document).ready(function () {
	/*
	 *  Init Slider
	 *
	 */

	$('.slick-slider').slick();

	$('.slick-three').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: true,
		responsive: [
			{
				breakpoint: 960,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 380,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('.sync-staffelpreise-trigger').on('change', function () {
		var cartValue = $(this).val();

		// Sync indicator
		$('#sync-staffelpreise .staffelpreise-item').each(function () {
			var targetValue = $(this).data('minsize');

			if (cartValue >= targetValue) {
				$('#sync-staffelpreise .staffelpreise-item').removeClass('active-price');
				$(this).addClass('active-price');
			}
		});

		// Sync price
		var $lastMatchingElement = undefined;
		var lastMatchingValue = 0;
		$('#sync-staffelpreise-price *[data-from-quantity]').each(function() {
			var $this = $(this);
			var targetValue = $this.data('from-quantity');
			$this.addClass('uk-hidden');

			if (cartValue >= targetValue && lastMatchingValue <= targetValue)
				$lastMatchingElement = $this;
		});
		if ($lastMatchingElement) $lastMatchingElement.removeClass('uk-hidden');
	});



	/*
	 *  Mobile Navigation Init
	 *
	 */

	$('#mobile-navigation').mmenu({
		extensions: ['pagedim-black'],
		navbar: {
			title: 'BKR Menü'
		}
	});

	$('#oc-categories').mmenu({
		extensions: ['pagedim-black'],
		navbar: {
			title: 'Kategorien'
		}
	}, {
		clone: true
	});

	$('#oc-filter').mmenu({
		extensions: ['pagedim-black'],
		offCanvas: {
			position: 'right'
		},
		navbar: {
			title: 'Filter'
		}
	}, {
		clone: true
	});



	/*
	 *  Big Text für Startseiten-Grid
	 *
	 */

	setTimeout(function () {
		$('.responsive-text').bigtext({
			minfontsize: 15,
			maxfontsize: 75
		});
	}, 100);

	$('#bkr-comment').mdcAjaxRating();



	/*
	 *  Scroll to top
	 *
	 */

	new (function(){
		var that				= this;
		this.$main				= $('main');
		this.$content			= $('#content');
		this.$containerToTop	= $('.wrapper-backtotop');

		this.isContainerToTopVisible	= false;
		this.update						= update;

		$(document).on('scroll', update);
		$(window).on('resize', update);

		function update(recalcSizes) {
			var posMainTop			= that.$main.position().top;
			var posContentBottom	= that.$content.height();
			var windowCurrent		= $(document).scrollTop();
			var isWindowInMain		= posMainTop < windowCurrent;

			if (isWindowInMain && !that.isContainerToTopVisible) {
				that.isContainerToTopVisible = true;
				that.$containerToTop.stop(true).fadeIn(200);
			} else if (!isWindowInMain && that.isContainerToTopVisible) {
				that.isContainerToTopVisible = false;
				that.$containerToTop.stop(true).fadeOut(200);
			}

			if (isWindowInMain) {
				that.$containerToTop.css({
					display		: '',
					position	: '',
					top			: '',
					bottom		: '',
				});

				var posBtnToTop 	= that.$containerToTop.position().top;
				var isEleBelowMain	= posContentBottom < posBtnToTop + windowCurrent;

				if (isEleBelowMain) that.$containerToTop.css({
					position	: 'absolute',
					top			: posContentBottom + 'px',
					bottom		: 'auto',
				});
			}
		}
	})();



	/*
	 *  Init plugins
	 *
	 */

	window.StateManager
		.addPlugin('.listing--container', 'bkrModalStaffelpreise');
});
