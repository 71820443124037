;(function($, window, undefined) {
	'use strict';

	$.plugin('mdcAjaxRating', {
		defaults: {
			formSelector	: 'form',
			errorsSelector	: '.errors',
			errorSelector	: '.error',
		},

		init : function() {
			var that = this;

			// Status
			this.pending = false;

			// Container
			this.$form				= this.$el.find(that.opts.formSelector);
			this.$containerErrors	= this.$el.find(that.opts.errorsSelector);
			this.$containerForm		= that.$form.find('.rating-form');
			this.$containerSuccess	= that.$form.find('.rating-success');
			this.$containerSuccess.hide();

			// Interaktion
			this.$inName			= this.$form.find('input[name=sVoteName]');
			this.$inMail			= this.$form.find('input[name=sVoteMail]');
			this.$inCaptcha			= this.$form.find('input[name=sCaptcha]');
			this.$btnSend			= this.$el.find('*[type=submit]');

			this.setEvents();
		},

		setEvents : function() {
			var that = this;

			this.$form.on('submit', $.proxy(that.onSubmit, this));
		},

		// Nimmt ein Array oder Object an das Fehlermeldungen enthält und stellt
		// sie als uk-alert-warning dar. Alte Meldungen werden dabei entfernt
		showErrors : function(err) {
			var that = this;

			// Array oder Object?
			var newErrors = [];
			if (Array.isArray(err)) {
				for (var i = 0; i < err.length; i++)
					createErr(err[i]);
			} else {
				var errorKeys = Object.keys(err);
				for (var i = 0; i < errorKeys.length; i++)
					createErr(err[errorKeys[i]]);
			}

			// Alte Fehler verfügbar? Wenn ja, ausblenden und das Einblenden
			// von neuen Fehlern als Callback hinterlegen
			var errors = that.$containerErrors.find(that.opts.errorSelector);
			if (errors.length) {
				errors.slideUp(function() {
					$(this).remove();
					show();
				});
			} else {
				show();
			}

			function createErr(err) {
				var newError = $('<div class="error uk-alert uk-alert-warning uk-margin-top">'+err+'</div>');
				newError.hide();
				newErrors.push(newError);
			}

			function show() {
				for (var i = 0; i < newErrors.length; i++) {
					that.$containerErrors.append(newErrors[i]);
					newErrors[i].slideDown();
				}
			}
		},

		onSubmit : function(e) {
			e.preventDefault();

			var that = this;
			var url = this.$form.attr('data-ajax-action');

			if (this.pending) return;
			this.pending = true;

			// Pending feedback
			this.$btnSend.each(function() {
				if (!this.contentCache)
					this.contentCache = $(this).html();
				$(this).html('Bitte warten...');
			});

			$.ajax({
				url			: url,
				type		: 'POST',
				data		: this.$form.serialize(),
				dataType	: 'json',
				success		: $.proxy(that.responseHandler, that),
				fail		: function(response) {
					that.showErrors([
						'Die Anfrage ist fehlgeschlagen. Versuche es bitte später noch einmal'
					]);
					that.pending = false;

					// Versecke pending feedback
					this.$btnSend.each(function() {
						$(this).html(this.contentCache);
					});
				},
			});

			$.publish('plugin/mdcAjaxRating/onTriggerRequest', [ that, e, url ]);
		},

		// Wenn die Kommunikation mit dem Server erfolgreich war, wird hier
		// die Antwort bearbeitet. War die Anfrage erfolgreich, wird das
		// Formular ausgeblendet und eine Erfolgsnachricht eingeblendet.
		// War die Anfrage erfolglos, werden ggf. Fehlertext über dem Formular
		// eingeblendet
		responseHandler: function(response) {
			var that = this;
			this.pending = false;

			// Versecke pending feedback
			this.$btnSend.each(function() {
				$(this).html(this.contentCache);
			});

			// Anfrage war erfolgreich
			if (response.success === true) {

				// Ist eine Bestätigung nötig, weise den Benutzer darauf hin
				var $confirmInfo = that.$containerSuccess.find('.check-mails');
				if (response.needsConfirmation) $confirmInfo.show();
				else $confirmInfo.hide();

				// Zeige Erfolgsnachricht
				that.$containerForm.slideUp(function() {
					that.$containerSuccess.slideDown();
				});

			// Anfrage war nicht erfolgreich / Zeige Fehler
			} else {
				that.showErrors(response.errorMessages);

				// Markiere ggf. fehlerhafte Eingabefelder
				var blameInput = function(input) {
					input.addClass('uk-form-danger');
					input.on('focus', removeDanger);

					function removeDanger() {
						input.removeClass('uk-form-danger');
						input.off('focus', removeDanger);
					}
				}

				if (response.sErrorFlag.sName) blameInput(that.$inName);
				if (response.sErrorFlag.sMail) blameInput(that.$inMail);
				if (response.sErrorFlag.sCaptcha) blameInput(that.$inCaptcha);
			}
		},
	});
})(jQuery, window);
